import React from "react";
import { useNavigate } from "react-router-dom";

export default function About() {
    const navigate = useNavigate(); // Initialize the useNavigate hook

    // Function to navigate to the About Us page
    const navigateToAboutUs = () => {
        navigate("/aboutus"); // Adjust the path based on your routing structure
    };

    return (
        <>
            <div className="about-bg" id="aboutus">
                <div className="about-mainhead">
                    <h1>About Us</h1>
                    <div className="about-content">
                        <div className="about-text">
                            <p>
                                Welcome to Finesse Academy for Bakery & Pastry Arts, where every class is a sweet adventure! At Finesse, we believe that baking is more than just a skill—it's an art form, a passion, and a way to express creativity. Our academy is dedicated to helping you unleash your culinary potential, whether you're taking your first steps in the world of baking or looking to refine your pastry expertise.
                                <br/>
                                <span className="read-more"  onClick={navigateToAboutUs}>
                                     Read More ▶
                                </span>
                            </p>
                        </div>
                        <div className="about-image">
                            <img src="images/aboutchef.png" alt="About Us" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

import React from "react";
import { Button, Container } from "react-bootstrap";

export default function Chef() {
    return (
        <>
        <Container className="chef-space">
            <div className="chef_bg">
                <div className="chef-text">
                    <div className="head-send">
                        <div className="main-head">
                            <h1>Dreaming of a Career in Pastry?</h1>
                            <p>Start Your Journey with Us!</p>
                        </div>
                        <div className="send-btn">
                            <Button className="centered-button">Enroll Now</Button>
                        </div>
                    </div>
                </div>
            </div>
            </Container>
        </>
    )
}
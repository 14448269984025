import React from "react";
import {Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function Popularclass() {
    const classes = [
        {
            image: "images/merin.webp",
            date: "Timing 10 AM to 5 PM: 1 Days",
            title: "1 Day Workshop",

        },
        {
            image: "images/entre.webp",
            date: "Timing 10 AM to 5 PM: 3 Days",
            title: "Short Term Course",

        },
        {
            image: "images/choco.webp",
            date: "Coming Soon",
            title: "Diploma Course",

        },

    ];
    const navigate = useNavigate(); // Initialize the useNavigate hook

    // Function to navigate to the About Us page
    const navigateToCourses = () => {
        navigate("/courses"); // Adjust the path based on your routing structure
    };
    return (
        <>
            <div className="class-head" id="course">
                <div className="cooking-head">
                    <h1>Our Courses</h1>
                    {/* <img src="images/knife.webp" alt="Knife" /> */}
                </div>
                <div className="pop-class">
                    <Container>
                        <Row style={{ marginBottom: '5%' }}>
                            {classes.map((cls, index) => (

                                <Col md={4} key={index}>
                                    <Card className="cookie" style={{ border: 'none' }}>
                                        <Card.Body>
                                            <div className="image">
                                                <Card.Img src={cls.image} />
                                                <div className="cls-text">
                                                    <Card.Text>
                                                        <p className="cls-date">
                                                            <i className="fa fa-clock" style={{ color: "#e65c8c" }}></i>&nbsp;
                                                            {cls.date}
                                                        </p>
                                                    </Card.Text>
                                                    <Card.Title>
                                                        <h5>{cls.title}</h5>
                                                    </Card.Title>
                                                    {/* <Card.Text>
                                                            <p className="cls-para">{cls.description}</p>
                                                            <h6>LEARN MORE</h6>
                                                        </Card.Text> */}
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))
                            }
                        </Row>
                    </Container>
                </div>
                <button className="centered-button" onClick={navigateToCourses}>All Classes</button>
            </div>


        </>
    );
}

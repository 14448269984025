import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Onecourse from "./Onecourse";

export default function Courses() {
    const classes = [
        {
            image: "images/vienno.webp",
            date: "Timings:10 AM to 5 PM 3 Days",
            title: "Viennoiseries and enriched breads",
            description:"Butter croissant, almond croissant, pain au chocolat, cinnamon roll, fruit danish, brioche, doughnut, berliners, Japanese milk bread, hot cross bun, sandwich bread.",

        },
        {
            image: "images/puff.webp",
            date: "Timings:10 AM to 5 PM 3 Days",
            title: "Puff pastry and artisan breads",
            description:"Puff pastry lamination, palmier, apple turnover, vol au vents, jalousie, savoury puff, millefeuille, French baguette, ciabatta, focaccia, multigrain loaf, spiced whole wheat bread.",
        },
        {
            image: "images/entre.webp",
            date: "Timings:10 AM to 5 PM 3 Day ",
            title: "Entremet, petit gateau and verrines",
            description:"Coffee hazelnut, tropical, strawberry lime, the almond, red fruit vanilla, apple caramel, chocolate verrine, baked yogurt, pistachio pannacotta.",
        },
        {
            image: "images/choco.webp",
            date: "Timings:10 AM to 5 PM 3 Day ",
            title: "Chocolates and confectionery",
            description:"Bonbons, praline, pate de fruit, marshmallows, bars, millionaire shortbread, dragees, hand-rolled truffles, Brazilian beijinhos.",
        },
        {
            image: "images/cake.webp",
            date: "Timings:10 AM to 5 PM 3 Day ",
            title: "Cakes and icing",
            description:"Black forest, tropical, red velvet, lemon berry, rich chocolate, mocha.",
        },
        {
            image: "images/wedcake.webp",
            date: "Timings:10 AM to 5 PM 3 Day ",
            title: "Wedding cake and fondant design",
            description:"Gum paste, fondant modeling, wafer flowers, cake dummy draping, and stacking.",
        },
        {
            image: "images/dess.webp",
            date: "Timings:10 AM to 5 PM 3 Day ",
            title: "Classic desserts",
            description:"Tiramisu, baklava, choco lava cake, new york cheesecake, classic opera, yule log, charlotte, creme caramel, creme brulee, bread and butter pudding, clafoutis, pot de creme.",
        },

    ];
    return (
        <>
            <div className="aboutus_bg">
                <div className="aboutus-img-content">
                    <h1>Courses Offered</h1>
                </div>
            </div>
            <div className="class-head" id="course">
                
                <div className="pop-class">
                    <h3 style={{paddingTop:'3%',paddingBottom:'3%'}}>Short Term Programs</h3>
                    <Container>
                        <Row >
                            {classes.map((cls, index) => (

                                <Col md={4} key={index} style={{ marginBottom: '5%' }}>
                                    <Card className="cookie" style={{ border: 'none' }}>
                                        <Card.Body>
                                            <div className="image">
                                                <Card.Img src={cls.image} className="fixed-height-img"/>
                                                <div className="cls-text">
                                                    <Card.Text>
                                                        <p className="cls-date">
                                                            <i className="fa fa-clock" style={{ color: "#e65c8c" }}></i>&nbsp;
                                                            {cls.date}
                                                        </p>
                                                    </Card.Text>
                                                    <Card.Title>
                                                        <h5>{cls.title}</h5>
                                                    </Card.Title>
                                                    <Card.Text>
                                                            <p className="cls-para">{cls.description}</p>
                                                           
                                                        </Card.Text>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))
                            }
                        </Row>
                    </Container>
                </div>
                
            </div>
            <Onecourse/>
        </>
    )
}
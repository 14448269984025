// eslint-disable-next-line jsx-a11y/anchor-is-valid
import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';

const NavbarComponent = () => {

    

    return (
        <div className="catering-section">
            {/* Sidebar */}
            <div className='navlist-chef'>
                <Navbar collapseOnSelect expand='lg' className="sidebar">

                    {/* <Container> */}
                    <Navbar.Brand href="/" className="brand-logo">
                        <img src="images/logos.png" alt="Cake & Bake" style={{ width: '100px' }} />
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" className="justify-content-center" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="nav-menu">
                            <ul className="list_items d-flex justify-content-center">
                                <Nav.Link href="/">Home</Nav.Link>
                                <Nav.Link href="/aboutus">About Us</Nav.Link>
                                <Nav.Link href="/courses">Our Courses</Nav.Link>
                                <Nav.Link href="/contactus">Contact Us</Nav.Link>
                            </ul>
                        </Nav>
                    </Navbar.Collapse>

                    <div className='contact-media'>
                        <div className="contact-info">
                            <p><i class="fa-solid fa-envelope" style={{color: "#ffffff"}}></i> info@finesseacademy.in</p>
                            <p><i className="fa fa-phone" style={{color: "#ffffff"}}></i>&nbsp;+91 9445744663</p>
                            <p><i className="fa fa-clock" style={{color: "#ffffff"}}></i> &nbsp;Mon-Sat: 9am-5pm</p>
                        </div>
                        <div className="social-media">
                        <a href="https://www.facebook.com/finesseacademyarts" target="_blank"  rel="noreferrer"><i class="fa-brands fa-facebook-f" style={{color: "#d5a092"}}></i></a>
                    <a href="https://www.instagram.com/finesseacademyarts" target="_blank" rel="noreferrer"><i class="fa-brands fa-instagram" style={{color: "#d5a092"}}></i></a>
                    <a href="#"><i class="fa-brands fa-linkedin-in" style={{color: "#d5a092"}}></i></a>
                    <a href="#"><i class="fa-brands fa-youtube " style={{color: "#d5a092"}}></i></a>
                        </div>
                    </div>

                    {/* </Container> */}

                </Navbar>
            </div>
        </div>



    );
}

export default NavbarComponent;

import React from "react";
import Maincontent from "./Maincontent";
import About from "./About";
import WhyChoose from "./Whychoose";
import Popularclass from "./Popularclass";
import Chef from "./Chef";
export default function Home(){
    return(
        <>
        <Maincontent/>
        <About/>
        <WhyChoose/>
        <Popularclass/>
        <Chef/>
        
        </>
    )
}
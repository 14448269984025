// eslint-disable-next-line jsx-a11y/anchor-is-valid
import React from "react";

export default function Profchef() {
    return (
        <>
            <div className="prof-chef">
                <div className="maschef">
                    <div className="master-chef">
                        <div className="prof-text">
                            {/* <p>Professional Chefs</p> */}
                            <h1>About Our Founder</h1>
                        </div>
                    </div>
                    <div className="team-section">
                        <div className="team-image">
                            <img src="images/Founder.webp" alt="Professional Chef" />
                        </div>
                        <div className="team-details">
                            <h2>Mohamed Shuaib</h2>
                            <h6>Executive Pastry Chef</h6>
                            <p>Highly experienced and
                                accomplished Executive Pastry Chef
                                with an impressive career spanning
                                over 17 years in the hotel and
                                restaurant industry. He has honed his
                                craft and developed a unique
                                culinary style through his work with
                                prestigious brands such as Hyatt,
                                Hilton, Shangri-La, Alshaya, and
                                Oberoi Hotels. His culinary journey
                                has taken him across the globe,
                                allowing him to gain invaluable
                                experience and exposure in diverse
                                cultural settings, including the
                                Maldives, Qatar, Bahrain, Saudi
                                Arabia, Ghana, and China.</p>
                            <div className="social-icons">
                                <a href="https://www.facebook.com/finesseacademyarts" target="_blank" rel="noopener noreferrer"><i class="fa-brands fa-facebook-f" style={{ color: "white" }}></i></a>
                                <a href="https://www.instagram.com/finesseacademyarts" target="_blank" rel="noopener noreferrer"><i class="fa-brands fa-instagram " style={{ color: "white" }}></i></a>
                                <a href="#"><i class="fa-brands fa-linkedin-in" style={{ color: "white" }}></i></a>
                                <a href="#"><i class="fa-brands fa-youtube" style={{ color: "white" }}></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

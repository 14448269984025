import React from "react";

export default function Footer(){
    return(
        <>
         <div className="footer-bottom">
                    <p> © FINESSEACADEMY 2024 - All Rights Reserved | Powered by <span><a href="https://dgbirdmedia.com/" style={{textDecorationLine:'none'}}>DGBird Media</a></span> </p>
                </div>
        </>
    )
}
import React from "react";
import Profchef from "./ProfChef";

export default function Aboutus(){
    return(
        <>
        <div className="aboutus_bg">
            <div className="aboutus-img-content">
                <h1>About Us</h1>
            </div>
        </div>
        <div className="about-main-content">
                <div className="about-header-content">
                    <h1>About Finesse</h1>
                    <p>Welcome to Finesse Academy for Bakery & Pastry Arts, where every class is a sweet
                        adventure! At Finesse, we believe that baking is more than just a skill—it's an art form, a
                        passion, and a way to express creativity. Our academy is dedicated to helping you unleash
                        your culinary potential, whether you're taking your first steps in the world of baking or looking
                        to refine your pastry expertise. We're all about whipping up passion, kneading creativity, and
                        baking dreams into reality.
                        Our mission is simple: to transform your love for baking into a craft you can be proud of.<br/><br/>
                        Through our comprehensive and hands-on programs, we guide you through every stage of
                        the baking process, from mastering the fundamentals to exploring advanced techniques.
                        You'll learn the delicate art of pastries, the science of perfect bakes, and the secrets to
                        crafting desserts that are as beautiful as they are delicious.
                        At Finesse, every lesson is a treat, every challenge a delightful puzzle, and every creation a
                        masterpiece waiting to happen.
                        Join us at Finesse Academy for Bakery & Pastry Arts, and let's embark on a journey of
                        discovery, creativity, and culinary excellence. Whether you're aiming to become a
                        professional pastry chef or simply want to elevate your baking skills, we have the perfect
                        recipe for your success. Let's bake up something extraordinary and create delicious
                        memories together!</p>
                </div>
            </div>
        <Profchef/>
        </>
    )
}